import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { RouteComponentProps } from "@reach/router";
import { XLButton, SButton } from "@metyis-ds/button";
import {
  Body2Bold,
  Body2Light,
  Text1Bold,
  Text2Bold,
  Text2Roman,
  Text3Light,
  Text4Light,
  Title2Medium
} from "@metyis-ds/typography";
import PngMetyis from "@public/metyis.png";
import IllusCloud1 from "@illustrations/login/cloud1.svg";
import IllusCloud2 from "@illustrations/login/cloud2.svg";
import IllusCity from "@illustrations/login/city.svg";
import IllusTruck from "@illustrations/login/truck.svg";
import api from "@api";
import Layout from "@components/layouts/Layout";
import ContentLayout from "@components/layouts/ContentLayout";
import content from "./Gamemaster.content";
import { GameResponse } from "src/api/data-contracts";
import { geoAzimuthalEqualAreaRaw } from "d3";

const LabelComponent = styled.div<{ darkBackground?: boolean }>(
  ({ theme, darkBackground }) => css`
    height: 48px;
    border-radius: 16px;
    background-color: ${darkBackground
      ? theme.colors.grey.dark
      : theme.colors.white.dark};
    display: flex;
    align-items: center;
    padding: 0 16px;
    margin-top: ${theme.spaceUnit.md};

    ${darkBackground &&
    css`
      color: ${theme.colors.white.light};
      ${SButton} {
        color: ${theme.colors.white.light};
      }
    `}
  `
);

export const GamemasterRoute: React.FC<RouteComponentProps> = ({
  location
}) => {
  const [gameName, setGameName] = useState("");
  const [gameInfo, setGameInfo] = useState<GameResponse>();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [slideStatus, setSlideStatus] = useState("");

  console.log("GAME INFO ", gameInfo);

  const [_, gameId] = location!.pathname.split("/gamemaster/");

  const getGameInfo = async (game: string) => {
    try {
      const { data, status } = await api.postGame({
        gameid: game.toUpperCase()
      });
      setIsPageLoading(false);
      if (status === 200) {
        setGameInfo(data);
      } else {
        throw new Error();
      }
    } catch (e) {
      setHasError(true);
    } finally {
      setIsPageLoading(false);
    }
  };

  useEffect(() => {
    let interval:any;
    if (location) {
      if (gameId && gameId.length > 0) {
        setGameName(gameId);
        // TODO get charts and game slides
        getGameInfo(gameId);
        setInterval(() => {
          getGameInfo(gameId)
        }, 3000)
      }
    }
    return () => clearInterval(interval)
  }, [location]);

  const allowStart = async (gameid: string) => {
    const { status } = await api.postStartGame({
      gameid: gameName.toUpperCase()
    })
    if (status === 200) {
      window.location.reload();
    }
  }
  const resetGame = async (gameid: string) => {
    const { status } = await api.postResetGame({
      gameid: gameName.toUpperCase()
    });
    if (status === 200) {
      window.location.reload();
    }
  };

  const removePlayer = async (playerName: string) => {
    const { status } = await api.postKickUser({
      gameid: gameName.toUpperCase(),
      userid: playerName
    });
    if (status == 200) {
      console.log("Removed player ", playerName);
      window.location.reload();
    }
  };

  const downloadSlides = async () => {
    setSlideStatus("downloading");
    // api.postSlides({
    //   gameid: gameId.toUpperCase()
    // }).then(d => {
    //   console.log(d);
    // }).catch(err => {
    //   console.log("Err ", err)
    // })

    api
      .postSlides({
        gameid: gameId.toUpperCase()
      })
      .then(blob => {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob.data);
        link.download = `Slides.pdf`;
        //link.download = `(${gameId}) Slides.pdf`;
        // some browser needs the anchor to be in the doc
        document.body.append(link);
        link.click();
        link.remove();
        // in case the Blob uses a lot of memory
        setTimeout(() => URL.revokeObjectURL(link.href), 7000);
        setSlideStatus("");
      })
      .catch(err => {
        console.log("ERR ", err);
        setSlideStatus("error");
      });
  };

  const backToLogin = () => {
    window.location.href = "/";
  };

  console.log(gameInfo)

  return (
    <Layout division="half">
      <div className="relative bg-sky-gradient overflow-hidden ">
        {/* <img src={PngMetyis} className="absolute top-10 left-10" /> */}
        <IllusCity className="absolute w-auto bottom-0 right-0 h-3/5" />
        <IllusTruck className="absolute w-auto h-auto bottom-0 left-0" />
        <IllusCloud1 className="absolute w-auto h-auto top-20 right-8" />
        <IllusCloud2 className="absolute w-auto h-auto  top-60 left-12" />
      </div>
      <ContentLayout loading={isPageLoading} hideHeader>
        {isPageLoading ? (
          <div></div>
        ) : hasError ? (
          <div>
            <Body2Bold>{content.error}</Body2Bold>
          </div>
        ) : (
          <div style={{ margin: "-30px"}}>
            <Body2Bold>{content.title}</Body2Bold>
            <Title2Medium className="mt-xs mb-xl">{gameId}</Title2Medium>
            {(gameInfo?.game || 0) > 3 && (
              <Body2Light className="mb-lg" as="p">
                {content.gameStarted}
              </Body2Light>
            )}
            <Body2Light className="mb-lg" as="p">
              {content.description}
            </Body2Light>
            <Body2Light className="mb-lg" as="p">
              {content.addPlayersInfo}
            </Body2Light>

            <section>
              <Text2Roman>{content.room}</Text2Roman>
              <LabelComponent darkBackground>
                <Text1Bold className="flex-1">{gameId}</Text1Bold>
                <div className="flex flex-row items-center">
                  {gameId && gameId.indexOf("ONLINE") !== -1 && <div className="mx-2">
                    {!(gameInfo?.check_start) && <SButton onClick={allowStart}>{content.allowStart}</SButton>}
                    {gameInfo?.check_start && <div>Game has Started!</div>}
                  </div>}
                  <div className="mx-2">
                    <SButton onClick={resetGame}>{content.resetGame}</SButton>
                  </div>
                </div>
              </LabelComponent>

              { gameInfo && gameInfo.scores && gameInfo.scores.length > 0 && gameId && gameId.indexOf("ONLINE") !== -1 && <LabelComponent darkBackground>
                <Text1Bold className="flex-1">{content.saveResultsInfo}</Text1Bold>
                <div className="mx-2">
                  <SButton onClick={() => console.log("SAving")}>{content.saveResultsAction}</SButton>
                </div>
              </LabelComponent>}
            </section>

            {/* <section className="my-md">
              <Text2Roman>{content.gameCode}</Text2Roman>
              <LabelComponent darkBackground>
                <Text1Bold className="flex-1">{gameInfo.code}</Text1Bold>
                <SButton onClick={resetGame}>{content.resetCode}</SButton>
              </LabelComponent>
            </section> */}

            <section className="my-xxl">
              <Text2Roman>{content.players}</Text2Roman>
              {gameInfo?.players && gameInfo["players-info"] &&
                gameInfo.players.map((playerName,index) => (
                  <LabelComponent key={"player"+ index}>
                    <Text2Bold className="flex-1">{playerName}</Text2Bold>

                    {gameInfo["scores"] && gameInfo["scores"].length > 0 && <>
                      <div className="flex flex-col justify-center items-center">
                        <Text2Roman>{(+gameInfo["scores"][index]).toFixed(0)}</Text2Roman>
                        <Text3Light>Points</Text3Light>
                      </div>
                      <div className="w-[1px] h-full bg-black py-md mx-md" />
                    </>}

                    {gameInfo["players-info"] && gameInfo["players-info"].length > 0 && 
                      <>
                        <Text4Light>{gameInfo["players-info"][index]}</Text4Light>
                        <div className="w-[1px] h-full bg-black py-md mx-md" />
                      </>}
                    <SButton onClick={() => removePlayer(playerName)}>
                      {content.removePlayer}
                    </SButton>
                  </LabelComponent>
                ))}
            </section>
          </div>
        )}
        <XLButton
          onClick={hasError ? backToLogin : downloadSlides}
          disabled={
            slideStatus == "downloading" ||
            isPageLoading ||
            (!isPageLoading && !hasError && !(gameInfo && gameInfo.game)) // download slides yet to be implemented
          }
        >
          {hasError
            ? content.ctaError
            : slideStatus == "downloading"
            ? "Loading Slides"
            : content.cta}
        </XLButton>
      </ContentLayout>
    </Layout>
  );
};

export default GamemasterRoute;
